.error-screen {
  background-color: hsl(0, 0%, 100%);
  width: 100vi;
  height: 100vb;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-block-start: 4rem;
  box-sizing: border-box;

  .pelckmans-logo {
    width: 16rem;
  }

  .back-to-home {
    margin-block-start: 1rem;
  }

  /**
   * Extra styles that are added because of the separation between old design and ianua design.
   */
  html:has(&) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
  }

  body:has(&) {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  .heading-1 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 2.75rem;
    margin: 0 0 1.5rem;
    color: #004457;
  }

  .bc-button__button {
    border: 1px solid;
    padding: 12px 24px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #77a1aa;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    word-wrap: break-word;
    -webkit-transition: background 0.15s ease, border-color 0.15s ease, color 0.15s ease, -webkit-box-shadow 0.15s ease,
      -webkit-transform 0.15s ease;
    transition: background 0.15s ease, border-color 0.15s ease, color 0.15s ease, -webkit-box-shadow 0.15s ease,
      -webkit-transform 0.15s ease;
    transition: background 0.15s ease, box-shadow 0.15s ease, border-color 0.15s ease, color 0.15s ease,
      transform 0.15s ease;
    transition: background 0.15s ease, box-shadow 0.15s ease, border-color 0.15s ease, color 0.15s ease,
      transform 0.15s ease, -webkit-box-shadow 0.15s ease, -webkit-transform 0.15s ease;
  }
  .bc-button__button:after {
    clear: both;
    content: '';
    display: table;
  }
  @media (hover: hover) {
    .bc-button__button:hover {
      background-color: #77a1aa;
      border-color: #77a1aa;
      color: #fff;
      -webkit-box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25), 0 6px 16px 0 hsla(0, 0%, 58.8%, 0.15);
      box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25), 0 6px 16px 0 hsla(0, 0%, 58.8%, 0.15);
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
    }
  }
  @media (hover: none) {
    .bc-button__button:active {
      background-color: #77a1aa;
      border-color: #77a1aa;
      color: #fff;
      -webkit-box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25), 0 6px 16px 0 hsla(0, 0%, 58.8%, 0.15);
      box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25), 0 6px 16px 0 hsla(0, 0%, 58.8%, 0.15);
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
    }
  }
  .bc-button__button .bc-icon {
    width: 16px;
    height: 16px;
  }
  .bc-button__button .bc-icon--left {
    margin-right: 8px;
  }
  .bc-button__button .bc-icon--right {
    margin-left: 8px;
  }
  .bc-button__button + .bc-button__button {
    margin-left: 16px;
  }
  .bc-button__outline.bc-button__danger,
  .bc-button__outline.bc-button__primary,
  .bc-button__outline.bc-button__secondary {
    border: 1px solid;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #77a1aa;
  }
  @media (hover: hover) {
    .bc-button__outline.bc-button__danger:hover,
    .bc-button__outline.bc-button__primary:hover,
    .bc-button__outline.bc-button__secondary:hover {
      color: #fff;
      -webkit-box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25), 0 6px 16px 0 hsla(0, 0%, 58.8%, 0.15);
      box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25), 0 6px 16px 0 hsla(0, 0%, 58.8%, 0.15);
    }
  }
  @media (hover: none) {
    .bc-button__outline.bc-button__danger:active,
    .bc-button__outline.bc-button__primary:active,
    .bc-button__outline.bc-button__secondary:active {
      color: #fff;
      -webkit-box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25), 0 6px 16px 0 hsla(0, 0%, 58.8%, 0.15);
      box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25), 0 6px 16px 0 hsla(0, 0%, 58.8%, 0.15);
    }
  }
  .bc-button__outline.bc-button__primary:active,
  .bc-button__outline.bc-button__primary:hover {
    border-color: #45a567;
  }
  .bc-button__outline.bc-button__secondary:active,
  .bc-button__outline.bc-button__secondary:hover {
    border-color: #4f6e75;
  }
  .bc-button__outline.bc-button__danger:active,
  .bc-button__outline.bc-button__danger:hover {
    border-color: #c20000;
  }
  .bc-button__primary {
    border-color: transparent;
    color: #fff;
    background-color: #65ba7c;
    -webkit-box-shadow: 0 2px 0 0 #47ad6c;
    box-shadow: 0 2px 0 0 #47ad6c;
  }
  @media (hover: hover) {
    .bc-button__primary:hover {
      border-color: transparent;
      background-color: #45a567;
    }
  }
  @media (hover: none) {
    .bc-button__primary:active {
      border-color: transparent;
      background-color: #45a567;
    }
  }
  .bc-button__secondary {
    border-color: transparent;
    color: #fff;
    background-color: #67909a;
    -webkit-box-shadow: 0 2px 0 0 #53737b;
    box-shadow: 0 2px 0 0 #53737b;
  }
  @media (hover: hover) {
    .bc-button__secondary:hover {
      border-color: transparent;
      background-color: #4f6e75;
    }
  }
  @media (hover: none) {
    .bc-button__secondary:active {
      border-color: transparent;
      background-color: #4f6e75;
    }
  }
  .bc-button__danger {
    border-color: transparent;
    color: #fff;
    background-color: red;
    -webkit-box-shadow: 0 2px 0 0 #c00;
    box-shadow: 0 2px 0 0 #c00;
  }
  @media (hover: hover) {
    .bc-button__danger:hover {
      border-color: transparent;
      background-color: #c20000;
    }
  }
  @media (hover: none) {
    .bc-button__danger:active {
      border-color: transparent;
      background-color: #c20000;
    }
  }
  .bc-button__small {
    padding: 8px 24px;
    font-size: 12px;
  }
  .bc-button__small .bc-icon {
    font-size: 16px;
  }
  .bc-button__button:disabled {
    background: #e6e6e6;
    border: 0;
    -webkit-box-shadow: 0 2px 0 0 #ccc;
    box-shadow: 0 2px 0 0 #ccc;
    color: #fff;
    cursor: not-allowed;
    -webkit-transform: none;
    transform: none;
  }
  @media (hover: hover) {
    .bc-button__button:disabled:hover {
      background: #e6e6e6;
      border: 0;
      -webkit-box-shadow: 0 2px 0 0 #ccc;
      box-shadow: 0 2px 0 0 #ccc;
      color: #fff;
      cursor: not-allowed;
      -webkit-transform: none;
      transform: none;
    }
  }
  @media (hover: none) {
    .bc-button__button:disabled:active {
      background: #e6e6e6;
      border: 0;
      -webkit-box-shadow: 0 2px 0 0 #ccc;
      box-shadow: 0 2px 0 0 #ccc;
      color: #fff;
      cursor: not-allowed;
      -webkit-transform: none;
      transform: none;
    }
  }

  .body--bao {
    .bc-button {
      &__button {
        font-weight: 500;
        border-radius: 1.875rem;
        font-size: 0.875rem;
        line-height: 1.5rem;

        &:not(:disabled) {
          border-color: hsl(193, 50%, 54%);
          color: hsl(193, 50%, 54%);
        }

        &:hover {
          color: hsl(0, 0%, 100%);
        }
      }

      &__primary:not(:disabled) {
        box-shadow: 0 0.125rem 0 0 hsl(99, 56%, 39%);
      }

      &__secondary:not(:disabled) {
        box-shadow: 0 0.125rem 0 0 hsl(193, 44%, 43%);
      }

      &__danger:not(:disabled) {
        box-shadow: 0 0.125rem 0 0 #c9142b;
      }

      &__primary:not(.bc-button__outline),
      &__secondary:not(.bc-button__outline),
      &__danger:not(.bc-button__outline) {
        color: hsl(0, 0%, 100%);
        border-color: transparent;
      }

      &__outline:not(:disabled) {
        background-color: hsl(0, 0%, 100%);
        box-shadow: none;
      }
    }
  }

  .bc-button {
    &__button {
      &:not(:disabled):hover {
        background-color: hsl(193, 20%, 40%);
        border-color: hsl(193, 20%, 40%);
        box-shadow: 0 0.375rem 0.375rem -0.25rem rgba(0, 0, 0, 0.25), 0 0.375rem 1rem 0 rgba(150, 150, 150, 0.15);
      }
    }

    &__primary {
      background-color: hsl(136, 38%, 56%);
      box-shadow: 0 0.125rem 0 0 hsl(136, 38%, 46%);

      &:not(:disabled):hover {
        background-color: hsl(136, 38%, 46%);
        border-color: hsl(136, 38%, 46%);
      }
    }

    &__secondary {
      background-color: hsl(193, 20%, 50%);
      box-shadow: 0 0.125rem 0 0 hsl(193, 20%, 40%);

      &:not(:disabled):hover {
        background-color: hsl(193, 20%, 40%);
        border-color: hsl(193, 20%, 40%);
      }
    }

    &__danger {
      background-color: #fc1a36;
      box-shadow: 0 0.125rem 0 0 #c9142b;

      &:not(:disabled):hover {
        background-color: #c9142b;
        border-color: #c9142b;
      }
    }
  }
}
